import React from "react"
import { Form, Field } from "react-final-form"
import ReCAPTCHA from "react-google-recaptcha"
import emailValidator from "email-validator"
import axios from "axios"
import { showSuccess, showError } from "../domain/notifications"
import Translations from "../domain/translations"

const emailValid = value => (emailValidator.validate(value) ? undefined : Translations.form.errors.required)

const resetForm = (values, form) => {
  setTimeout(() => {
    Object.keys(values).forEach(key => {
      form.resetFieldState(key)
    })
    form.reset()
  })
}

const onSubmit = async (values, form) => {
  try {
    const { email } = values
    const recaptcha_token = await recaptchaRef.current.execute()

    await axios.post(`${process.env.WORDPRESS_BASE_URL}/superbom/v1/subscribers`, {
      recaptcha_token,
      email,
    })

    resetForm(values, form)
    showSuccess(Translations.notifications.success_email, Translations.notifications.thanks)
  } catch (e) {
    showError(Translations.notifications.error_email, Translations.notifications.try_later)
  }
}

const recaptchaRef = React.createRef();

const Newsletter = () => {
  return (
    <section className="newsletter-component is-padding-horizontal-4 is-padding-vertical-10 has-background-secondary">
      <div className="container">

        <div className="columns is-centered is-gapless is-multiline">
          <div className="column is-narrow is-12-touch">
            <div className="columns is-gapless">
              <div className="column is-narrow">
                <div className="is-size-2 has-text-primary is-margin-right-4 is-margin-top-4-touch">
                  <svg xmlns="http://www.w3.org/2000/svg" width="57.78" height="43.344" viewBox="0 0 57.78 43.344">
                    <g transform="translate(0.012 -12.476)">
                      <path className="a" d="M57.768,49.6c-.092.341-.185.682-.283,1.017a7.222,7.222,0,0,1-7,5.2c-14.877,0-29.753-.029-44.63,0A5.6,5.6,0,0,1,.174,51.775a5.691,5.691,0,0,1-.179-1.311V23.438A1.785,1.785,0,0,1,1.919,21.5h5.3V14.367a1.762,1.762,0,0,1,1.855-1.889H55.9a1.947,1.947,0,0,1,1.872,1.352ZM10.845,16.095V39.2c0,4.316.179,8.631-.139,12.982H50.483a3.628,3.628,0,0,0,3.669-3.7V16.1H10.822ZM3.629,25.142V50.3a1.808,1.808,0,1,0,3.611-.075V25.142H3.629Z" transform="translate(0 0)" />
                      <path className="a" d="M43.735,31.915H28.512a1.8,1.8,0,1,1-.11-3.605H59.079a1.8,1.8,0,0,1,.578,3.524,2.559,2.559,0,0,1-.722.075h-15.2Z" transform="translate(-11.26 -6.686)" />
                      <path className="a" d="M43.745,40.8H59.026a1.808,1.808,0,0,1,.52,3.547,2.357,2.357,0,0,1-.612.058H28.43a1.779,1.779,0,0,1-1.86-1.826A1.733,1.733,0,0,1,28.412,40.8Z" transform="translate(-11.224 -11.958)" />
                      <path className="a" d="M35.16,53.885h5.3a1.779,1.779,0,0,1,1.912,1.889v7.1a1.733,1.733,0,0,1-1.843,1.837H29.822a1.774,1.774,0,0,1-1.883-1.86V55.694a1.768,1.768,0,0,1,1.814-1.814h5.413Z" transform="translate(-11.802 -17.484)" />
                      <path className="a" d="M67.07,53.885h5.748a1.808,1.808,0,1,1,0,3.611H61.154a1.774,1.774,0,0,1-1.733-1.519,1.8,1.8,0,0,1,1.236-2.028,2.363,2.363,0,0,1,.664-.069H67.07Z" transform="translate(-25.085 -17.483)" />
                      <path className="a" d="M67.092,70H61.344a1.808,1.808,0,1,1,0-3.611h11.5a1.8,1.8,0,1,1,0,3.605H67.092Z" transform="translate(-25.102 -22.764)" />
                    </g>
                  </svg>

                </div>
              </div>
              <div className="column is-narrow">
                <div className="is-size-2 has-text-white is-margin-bottom-4-touch is-margin-right-4">
                  Assine nossa newsletter
                </div>
              </div>
              <div className="column is-narrow"></div>
            </div>
          </div>
          <div className="column is-narrow is-12-touch">


            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, invalid, submitting }) => (
                <form
                  onSubmit={handleSubmit}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
                  />

                  <div className="columns is-gapless">
                    <div className="column is-narrow">

                      <Field
                        name="email"
                        validate={emailValid}
                        type="text"
                        render={({ input }) => (
                          <div className="field is-margin-right-4 is-margin-bottom-4-touch">
                            <p className="control has-icons-left has-icons-right">
                              <input {...input} className="input is-rounded" type="email" placeholder="Seu e-mail" />
                              <span className="icon is-small is-left">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                                  className="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                              </span>
                            </p>
                          </div>
                        )}
                      />

                    </div>
                    <div className="column is-narrow">
                      <button disabled={submitting || invalid} id="home-newsletter-button" className={`button is-primary is-rounded ${submitting ? 'is-loading' : ''}`}>
                        <span className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.91" height="13.459" viewBox="0 0 13.91 13.459">
                            <g transform="translate(0 0)">
                              <path className="primary-white" d="M87,113.829v-5.071a.229.229,0,0,1,.208-.229l9.687-.963a.082.082,0,0,0,0-.163l-9.687-.963a.229.229,0,0,1-.208-.229V101.14a.229.229,0,0,1,.329-.206l13.133,6.344a.23.23,0,0,1,0,.414l-13.132,6.344A.231.231,0,0,1,87,113.829Z" transform="translate(-86.837 -100.755)" />
                              <path className="primary-white" d="M77.1,104.265V99.249a.171.171,0,0,1,.013-.1.133.133,0,0,1,.034-.026s.054-.01,0,0h0a.105.105,0,0,1,.022,0l.1-.01,1.056-.106,1.8-.179q1.068-.106,2.137-.213,1.044-.1,2.087-.208l1.644-.163.808-.08a.293.293,0,0,0,.189-.07.246.246,0,0,0-.021-.365.281.281,0,0,0-.146-.051c-.069-.008-.138-.013-.2-.021L85.46,97.54,83.6,97.354l-2.153-.214-2.052-.2-1.562-.155c-.222-.022-.448-.035-.668-.067h0c.024,0,.022.011,0,0a.084.084,0,0,1-.043-.045.131.131,0,0,1,0-.035c0-.043,0-.088,0-.131V92c0-.136,0-.273,0-.409,0-.082.043-.106.117-.074.186.082.368.178.55.265l1.14.552,1.578.763,1.847.892,1.962.948,1.926.931q.859.415,1.716.828l1.356.656.836.4.174.085a.057.057,0,0,0,.014.006l0,0h0a.154.154,0,0,1,.038.027c.029.043.021.08-.045.114-.152.078-.307.149-.461.222l-1.072.518-1.526.737-1.826.883-1.956.945-1.934.934-1.759.849q-.705.341-1.412.683l-.915.441-.216.1c-.014.006-.029.016-.045.022a.072.072,0,0,1-.1-.067c-.01-.2-.329-.206-.32,0a.4.4,0,0,0,.571.345.226.226,0,0,1,.029-.013l.2-.1.886-.429,1.4-.676,1.751-.846,1.95-.942,1.978-.956,1.838-.888,1.543-.745,1.089-.526c.155-.075.312-.146.464-.224a.393.393,0,0,0,0-.7c-.045-.024-.091-.045-.138-.067q-.393-.19-.787-.381l-1.326-.641-1.713-.827-1.924-.929-1.985-.96-1.878-.907-1.6-.774c-.39-.189-.779-.377-1.169-.565l-.577-.278-.032-.016a.393.393,0,0,0-.563.285,2.138,2.138,0,0,0-.006.336V96.4a1.071,1.071,0,0,0,.03.392.4.4,0,0,0,.349.245l.083.008.379.038,1.436.142,2.009.2,2.186.217,1.951.194,1.308.13.291.029.027,0s0,0,0,0c-.051.013-.106-.067-.075-.114s.118-.046.038-.038c-.286.029-.573.058-.859.085l-1.7.168q-1.058.106-2.116.21l-2.148.213-1.767.176-.979.1-.075.008a.4.4,0,0,0-.366.3,1.724,1.724,0,0,0-.01.352v4.806A.158.158,0,0,0,77.1,104.265Z" transform="translate(-76.78 -91.191)" />
                            </g>
                          </svg>
                        </span>
                        <span><strong>Enviar</strong></span>
                      </button>
                    </div>
                  </div>

                </form>
              )}
            />

          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
