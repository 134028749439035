import React from "react"
import { get } from "lodash"
import axios from "axios"
import { navigate } from "gatsby"
import Search from "./search"
import Router from "../domain/router"

class ProductSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = { products: [] }
  }

  componentDidMount() {
    axios.get(`/metadata/products.json`)
      .then((response) => {
        this.setState({ products: response.data.products })
      })
  }

  handleChange(selectedOption) {
    console.log('selectedOption', selectedOption)
    navigate(selectedOption.value)
  }

  render() {
    const { products } = this.state

    const options = products.map(post => {
      const ingredients_text = get(post, "acf.ingredients_text")
      const label = post.title
      const value = Router.productItemPath(post)
      const description = `${post.title} ${ingredients_text}`
      return { value, label, description }
    })

    return (
      <div className="product-search-component is-padding-horizontal-4-touch">
        <Search
          placeholder="Busque um produto aqui…"
          options={options}
          onChange={this.handleChange} />
      </div>
    )
  }
}

export default ProductSearch
