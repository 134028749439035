import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const HomeTips = () => {
  return (
    <section className="home-tips-component">
      <div className="home-tips-bg">
        <LazyLoadImage src="/images/home-tips.jpg" alt="Fundo" />
      </div>

      <div className="home-tips-content is-padding-horizontal-4-touch">
        <div className="container">
          <div className="columns is-gapless is-multiline">
            <div className="column is-5-desktop is-12-touch text-box-wrap">
              <div className="text-box">
                <h2 className="is-size-1 has-text-weight-bold has-text-secondary is-margin-bottom-6">
                  A natureza nos oferece muito mais
                </h2>
                <p className="is-font-size-18 is-line-height-28 has-text-white">
                  Todos os nossos produtos têm ingredientes da natureza como personagem principal,
                  as uvas selecionadas deram origem ao nosso tradicional suco integral e as diversas
                  opções de mel levam o nome Superbom. Damos importância para esse tema porque
                  sabemos que é da natureza que podemos extrair os melhores remédios naturais,
                  os quais podem contribuir e muito para uma excelente qualidade de vida.
                  Por isso, convidamos você a conhecer os chamados 8 remédios naturais!
                </p>
              </div>
            </div>
            <div className="column is-1-desktop is-12-touch"></div>
            <div className="column is-6-desktop is-12-touch">
              <div className="columns is-mobile icon-box">
                <div className="column">
                  <TipItem src="/images/icons/fruta.svg" number="1" title="Alimentação Saudável" />
                  <TipItem src="/images/icons/vento.svg" number="3" title="Respiração de ar puro" />
                  <TipItem src="/images/icons/peso.svg" number="5" title="Prática de exercício físico" />
                  <TipItem src="/images/icons/temperanca.svg" number="7" title="Exerça a temperança" />
                </div>
                <div className="column">
                  <TipItem src="/images/icons/gota.svg" number="2" title="Ingestão de água" />
                  <TipItem src="/images/icons/sol.svg" number="4" title="Exposição à luz solar" />
                  <TipItem src="/images/icons/repouso.svg" number="6" title="Faça repouso" />
                  <TipItem src="/images/icons/espiritualidade.svg" number="8" title="Tenha sua espiritualidade" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const TipItem = ({ src, number, title }) => (
  <div className="tip-item">
    <div className="columns is-gapless">
      <div className="column is-narrow">
        <div className="item-image">
          <LazyLoadImage src={src} alt={title} />
        </div>
      </div>
      <div className="column is-narrow item-info">
        <div className="is-family-primary is-size-2 has-text-weight-bold has-text-secondary">
          {number}.
        </div>
        <div className="is-family-primary is-size-6 has-text-weight-bold has-text-white">
          {title}
        </div>
      </div>
    </div>
  </div>
)

export default HomeTips
