import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"

const HomeBannerProduct = () => {
  return (
    <section className="home-banner-product-component">
      <LazyLoadImage src="/images/home-banner-product.jpg" alt="Onde comprar" className="slider-bg is-hidden-mobile" />
      <div className="slide-info container">

        <div className="columns is-gapless">
          <div className="column is-4">
            <div className="is-padding-horizontal-4-touch">
              <h1 className="info-title">
                Produtos nutritivos
                e saborosos
                </h1>
              <p className="info-description">
                Sucos naturais, matinais e até mesmo deliciosos salgadinhos saudáveis.
                Nosso portfólio tem uma infinidade de produtos de altíssima qualidade,
                e todos eles têm algo em comum: uma preocupação especial com o valor nutricional,
                aliada a um sabor de dar água na boca.
                </p>
              <Link to="/onde-comprar" id="destaque-onde-comprar" className="button is-rounded is-primary">
                <strong>Onde Comprar</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeBannerProduct
