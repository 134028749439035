import React from "react"
import { Link } from "gatsby"
import ProductSearch from "./product-search"
import ProductTabs from "./product-tabs"

const HomeProducts = () => {
  return (
    <section className="home-products-component">
      <div className="container is-margin-top-12">
        <div className="has-text-centered has-text-left-mobile is-margin-horizontal-4 is-margin-bottom-10 is-margin-bottom-8-touch">
          <h2 className="title is-1">Nossos Produtos</h2>
        </div>
        <div className="is-margin-bottom-6">
          <ProductSearch />
        </div>
        <div className="is-margin-bottom-6">
          <ProductTabs showContent={true} />
        </div>
        <div className="is-margin-bottom-10 has-text-centered">
          <Link to="/produtos/" id="home-produtos" className="button is-primary is-inverted is-uppercase has-text-weight-bold">
            Ver mais produtos
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeProducts
