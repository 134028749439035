import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"

const HomeAbout = () => {
  return (
    <section className="home-about-component has-background-primary">

      <div className="columns is-gapless is-multiline">
        <div className="column is-6">
          <LazyLoadImage src="/images/fachada-superbom.jpg" alt="Fachada superbom" className="object-image" />
          {/* <figure className="image is-full is-left image-box with-fix-height" style={{ minHeight: 300 }}>
            
          </figure> */}
        </div>
        <div className="column is-6">
          <div className="is-padding-10 is-padding-horizontal-4-touch has-text-white object-info">
            <div>
              <h2 className="is-size-1 is-size-2-touch has-text-weight-bold is-margin-bottom-7">
                Nós somos a Superbom
              </h2>
            </div>
            <div className="is-margin-bottom-7">
              <p className="is-size-6" style={{ lineHeight: '28px' }}>
                Somos uma empresa alimentícia especializada na fabricação de produtos saudáveis
                e o nosso nome nasceu da atenção especial com a qualidade dos nossos produtos,
                essência esta que faz parte da nossa história desde o nosso marco inicial.
              </p>
            </div>
            <div>
              <Link to="/empresa/" className="button is-rounded">
                <strong>Saiba Mais</strong>
             </Link>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default HomeAbout
