import React from "react"
import { graphql } from "gatsby"
import LazyLoad from "react-lazy-load"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeProducts from "../components/home-products"
import HomeAbout from "../components/home-about"
import HomeSliderMain from "../components/home-slider-main"
import HomeBannerProduct from "../components/home-banner-product"
import Newsletter from "../components/newsletter"
import HomeBlog from "../components/home-blog"
import HomeTips from "../components/home-tips"
import HomeContact from "../components/home-contact"

const Page = ({ data }) => {
  const post = data.wordpressMainPage

  return (
    <Layout>
      <SEO post={post} />
      <HomeSliderMain />
      <LazyLoad><HomeProducts /></LazyLoad>
      <LazyLoad><HomeAbout /></LazyLoad>
      <LazyLoad><HomeBannerProduct /></LazyLoad>
      <LazyLoad><Newsletter /></LazyLoad>
      <LazyLoad><HomeBlog /></LazyLoad>
      <LazyLoad><HomeTips /></LazyLoad>
      <LazyLoad><HomeContact /></LazyLoad>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "home"}) {
      ...MainPageFull
    }
  }
`
