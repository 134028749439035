import React from "react"
import { get } from "lodash"
import SliderMain from "./slider-main"
import { StaticQuery, graphql } from "gatsby"
import Thumbnail from "../domain/thumbnail"

const HomeSliderMainContainer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressBanner(
            filter: {status: {eq: "publish"}},
            sort: {order: ASC, fields: title}
          ) {
            nodes {
              ...BannerFull
            }
          }
        }
      `}
      render={data => {
        return <HomeSliderMain
          posts={data.allWordpressBanner.nodes} />
      }}
    />
  )
}

const HomeSliderMain = ({ posts }) => {
  const slides = posts.map((post, index) => ({
    name: post.name,
    content: <SlideContent post={post} index={index} />
  }))

  return (
    <div className="home-slider-main-component">
      <SliderMain slides={slides} theme="white" />
    </div>
  )
}

const SlideContent = ({ post, index }) => {
  const banner_src = Thumbnail.bannerImage(post)
  const banner_title = get(post, "acf.banner_title")
  const banner_description = get(post, "acf.banner_description")
  const button_label = get(post, "acf.button_label")
  const banner_link = get(post, "acf.banner_link")
  const background_color = get(post, "acf.background_color")
  const theme = get(post, "acf.theme")

  return (
    <div className="slider-item home-slider-component" style={{ backgroundColor: background_color }}>
      <div className="slider-filling"></div>

      <picture>
        <source media="(min-width: 750px)" srcSet={banner_src} />
        <img src="/images/invisible.png" alt={banner_title} className="slider-bg" />
      </picture>

      <div className="slide-info container">

        <div className="columns is-gapless">
          <div className="column is-4">
            <div className="has-text-white is-padding-horizontal-4-touch">
              <h1 className={`info-title ${theme === 'black' ? 'has-text-black' : 'has-text-white'}`}>
                {banner_title}
              </h1>
              <p className={`info-description ${theme === 'black' ? 'has-text-black' : 'has-text-white'}`}>
                {banner_description}
              </p>
              <a href={banner_link} id={`banner-home-${index}`} className={`banner-home-button button is-rounded ${theme === 'black' ? 'is-black' : ''}`}>
                <strong>{button_label}</strong>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HomeSliderMainContainer
