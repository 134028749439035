import React from "react"
import { get } from "lodash"
import { Link, StaticQuery, graphql } from "gatsby"
import LazyLoad from "react-lazy-load"
import Thumbnail from "../domain/thumbnail"
import Router from "../domain/router"

const HomeBlogContainer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(filter: {status: {eq: "publish"}}, sort: {order: DESC, fields: date}, limit: 3) {
            nodes {
              ...PostItem
            }
          }
        }
      `}
      render={data => {
        return <HomeBlog posts={data.allWordpressPost.nodes} />
      }}
    />
  )
}

const HomeBlog = ({ posts }) => {
  return (
    <section className="home-products-component">
      <div className="container is-padding-top-11 is-padding-horizontal-4-touch">
        <div className="columns is-centered is-gapless">
          <div className="column is-6">
            <div className="has-text-centered has-text-left-mobile is-margin-bottom-8">
              <div className="is-margin-bottom-8">
                <h2 className="title is-1">Blog da Superbom</h2>
              </div>
              <div>
                <p>
                  Saúde por completo você encontra na Superbom.
                  Aprenda a ter uma vida mais equilibrada com dicas que fazem toda a diferença.
                  Vem ser saudável com a gente.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="is-margin-bottom-6 is-padding-horizontal-10 is-padding-horizontal-0-touch">
          <div className="columns">
            <div className="column">
              {posts[0] && <BlogItem post={posts[0]} />}
            </div>
            <div className="column">
              {posts[1] && <BlogItem post={posts[1]} />}
            </div>
            <div className="column">
              {posts[2] && <BlogItem post={posts[2]} />}
            </div>
          </div>
        </div>

        <div className="is-margin-bottom-10 has-text-centered">
          <Link to={Router.blogPath("post")} className="button is-dark is-inverted is-uppercase has-text-weight-bold">
            VER MAIS NOTÍCIAS
          </Link>
        </div>
      </div>
    </section>
  )
}

const BlogItem = ({ post }) => {
  const link = Router.blogPostPath(post)
  const primary_category = 'home-blog-item category-' + get(post, "primary_category.slug")

  return (
    <div>
      <div className="is-margin-bottom-1">
        <Link to={link} className={primary_category}>
          <LazyLoad>
            <figure className="image is-2by1 image-box with-fix-width is-round-2 has-background-black">
              <img src={Thumbnail.postGridImage(post)} alt={post.title} />
            </figure>
          </LazyLoad>
        </Link>
      </div>
      {post.primary_category &&
        <div className="is-margin-bottom-1">
          <Link to={Router.blogCategoryPath(post.type, post.primary_category.slug)} className={`is-uppercase has-text-weight-bold has-text-grey ${primary_category}`}>
            {post.primary_category.name}
          </Link>
        </div>}
      <div className="is-margin-bottom-2">
        <Link to={link} className={`is-size-5 has-text-weight-bold has-text-black ${primary_category}`}>
          {post.title}
        </Link>
      </div>
      <p className="is-margin-bottom-4 has-text-grey">
        {post.excerpt}
      </p>
      <Link to={link} className={`button is-primary is-rounded ${primary_category}`}>
        <strong>Ler Mais</strong>
      </Link>
    </div>
  )
}

export default HomeBlogContainer
