import React from "react"
import { get } from "lodash"
import Slick from "react-slick"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Router from "../domain/router"
import Thumbnail from "../domain/thumbnail"

const ProductSlide = ({ posts }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <div className="product-slide-component slick-default-dots">
      <Slick {...settings}>
        {posts.map(post => {
          const primary_category = 'product-slide category-' + get(post, "primary_categoria_produtos.slug")

          return (
            <div key={post.post_id} className="product-item">
              <Link to={Router.productItemPath(post)} className={`product-thumb ${primary_category}`}>
                <LazyLoadImage src={Thumbnail.productGridImage(post)} alt={post.title} />
              </Link>
              <Link to={Router.productItemPath(post)} className={`product-title has-text-black ${primary_category}`}>
                {post.title}
              </Link>
            </div>
          )
        })}
      </Slick>
    </div>
  )
}

export default ProductSlide
